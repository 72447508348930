<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import StatDashboard from "@/components/stat_dashboard";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tableau de bord",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    StatDashboard
  },
  data() {
    return {
      title: "Tableau de bord",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Vue Globale",
          active: true,
        },
      ],
      statData: [
        {
          icon: "bx bx-copy-alt",
          title: "Utiilisateurs",
          value: "2000000",
        },
        {
          icon: "bx bx-archive-in",
          title: "Moyens",
          value: "100",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Conversions",
          value: "30",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Transactions",
          value: "20",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Echanges",
          value: "32",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Continents",
          value: "5",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Pays",
          value: "30",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Devises",
          value: "20",
        },
      ],
      transactions: [],
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      loader: "spinner",
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      timeout: 3000, //ms
      fetchingStats: true,
    };
  },
  methods: {
    loadtransactions() {
      fetch(Helper.route('transactions'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log("transactions : ",data);
        this.transactions = data.reverse()
      })
    },
    loadstatistics() {
      fetch(Helper.route('admin/statistics'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log("statistics : ",data);
        this.statData = data
      })
    },
  },
  mounted() {
    this.loadtransactions()
    this.loadstatistics()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!-- end col -->
      <div>
        <div class="row">
          <div class="col-md-3">
            <StatDashboard :title="'Transactions'" :value="statData.transactions" />
          </div>
          <div class="col-md-3">
            <StatDashboard :title="'Utilisateurs'" :value="statData.users" />
          </div>
          <div class="col-md-3">
            <StatDashboard :title="'Moyens'" :value="statData.moyens" />
          </div>
          <div class="col-md-3">
            <StatDashboard :title="'Echanges'" :value="statData.exchanges" />
          </div>
          <div class="col-md-3">
            <StatDashboard :title="'Conversions'" :value="statData.conversions" />
          </div>
          <div class="col-md-3">
            <StatDashboard :title="'Devises'" :value="statData.currencies" />
          </div>
          <div class="col-md-3">
            <StatDashboard :title="'Continents'" :value="statData.continents" />
          </div>
          <div class="col-md-3">
            <StatDashboard :title="'Pays'" :value="statData.countries" />
          </div>
          <div class="col-lg-12 m-0">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-4">Dernière Transaction</h4>
                <!-- Transactions table -->
                <div class="table-responsive">
                  <table class="table align-middle mb-0">
                    <thead class="bg-light rounded">
                      <tr>
                        <th>#</th>
                        <th>iD</th>
                        <th>Nom</th>
                        <th>Date</th>
                        <th>Statut</th>
                        <th>Voir Detail</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(transaction, i) in transactions" v-show="i < 10" :key="transaction.id">
                        <th scope="row">{{ i + 1 }}</th>
                        <td>{{ transaction.transaction_id }}</td>
                        <td>{{ transaction.name }}</td>
                        <td>{{ transaction.created_at }}</td>
                        <td>
                          <span v-if="transaction.status == 'EN ATTENTE' || transaction.status == 'CANCEL' || transaction.status == 'TRANSFERER'" class="badge badge-pill badge-soft-danger font-size-11">
                              {{ transaction.status }}
                          </span>
                          <span v-if="transaction.status == 'EN COURS'" class="badge badge-pill badge-soft-orange font-size-11">
                              {{ transaction.status }}
                          </span>
                          <span v-if="transaction.status == 'ECHOUE'" class="badge badge-pill badge-soft-danger font-size-11">
                              {{ transaction.status }}
                          </span>
                          <span v-if="transaction.status == 'PROUVEE' || transaction.status == 'TERMEINE'" class="badge badge-pill badge-soft-success font-size-11">
                              {{ transaction.status }}
                          </span>
                        </td>
                        <td>
                          <router-link class="rounded-pill btn-sm btn-primary px-3" :to="'/transactions/'+transaction.id">Voir detail</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

  </Layout>
</template>
